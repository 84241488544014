
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("ember/index", function(){ return i("ember-source/ember/index.js");});
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-page-title/-embroider-implicit-modules.js";
import "ember-qunit/-embroider-implicit-modules.js";
import "ember-velcro/-embroider-implicit-modules.js";
import "tracked-built-ins/-embroider-implicit-modules.js";
import "ember-cli-inject-live-reload/-embroider-implicit-modules.js";
import "ember-cli-typescript/-embroider-implicit-modules.js";
import "@ember/optional-features/-embroider-implicit-modules.js";
import "@ember/string/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "@ember/test-helpers/-embroider-implicit-modules.js";
import "ember-template-imports/-embroider-implicit-modules.js";
import "@fortawesome/ember-fontawesome/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import "@glimmer/tracking/-embroider-implicit-modules.js";
import "ember-cli-postcss/-embroider-implicit-modules.js";
import "broccoli-asset-rev/-embroider-implicit-modules.js";
import "ember-cli-app-version/-embroider-implicit-modules.js";
import "ember-cli-clean-css/-embroider-implicit-modules.js";
import "ember-cli-dependency-checker/-embroider-implicit-modules.js";
import "ember-data/-embroider-implicit-modules.js";
import "ember-fetch/-embroider-implicit-modules.js";
import "ember-load-initializers/-embroider-implicit-modules.js";
import "ember-resolver/-embroider-implicit-modules.js";
import "ember-source/-embroider-implicit-modules.js";
import "loader.js/-embroider-implicit-modules.js";
